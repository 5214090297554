import rules from 'assets/documents/rules.pdf';

export const defaultAfkTime = 10;

export const quizPromoCode = 'SHARIK';

export const youtubeVideoID = 'NEi84ZTSHoU';

export const links = {
  prostokvashino: 'https://prostokvashino.ru/',
  termsOfUse: 'https://prostokvashino.ru/terms-of-use.pdf',
  sbermarket: 'https://sbermarket.ru/marketing/prostokvashino-products',
  youtube: `https://www.youtube.com/embed/${youtubeVideoID}?autoplay=1`,
  rules,
};
